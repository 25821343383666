import React from "react"
import RestaurantMobileList from "src/modules/menu/restaurantMenu/RestaurantMobileList"
import RestaurantDesktopList from "src/modules/menu/restaurantMenu/RestaurantDesktopList"
import { useSelector } from "react-redux"
import { Flex } from "src/styled"
import { Loading } from "../components/common/Loading"
export default function Menurestauracja() {
    const { isLoading } = useSelector(state => state.menurestaurant)

    return (
        <>
            {!isLoading ? (
                <>
                    <RestaurantMobileList></RestaurantMobileList>
                    <RestaurantDesktopList></RestaurantDesktopList>
                </>
            ) : (
                <></>
            )}
        </>
    )
}
