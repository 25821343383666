import React from "react"
import styled from "styled-components"
import { Box, Button, Flex } from "src/styled"
import { devices } from "src/theme/device"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const StyledBox = styled(Box)`
    box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background: white;
    padding: 25px;
    ${devices.laptop} {
        padding: 15px;
    }
`

const Container = styled(Box)`
    width: 75%;
    margin: auto;
    ${devices.laptop} {
        width: 95%;
    }
`

const StyledInput = styled.input`
    border: none;
    width: 100%;
    font-size: 18px;

    ${devices.laptop} {
        font-size: 16px;
    }
`

const StyledButton = styled(Button)`
    color: white;
    background: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    ${devices.laptop} {
        font-size: 12px;
        padding: 5px 10px;
    }
`

export default ({ value, onChange }) => {
    return (
        <Container>
            <StyledBox>
                <Flex flexDirection="row">
                    <StyledInput
                        value={value}
                        onChange={({ currentTarget }) =>
                            onChange(currentTarget.value)
                        }
                        placeholder="Szukaj..."
                    />

                    <StyledButton>
                        <FontAwesomeIcon icon={faSearch} />
                    </StyledButton>
                </Flex>
            </StyledBox>
        </Container>
    )
}
