import React from "react"
import { Element } from "react-scroll"
import styled from "styled-components"
import { useSelector } from "react-redux"

import Card from "./Card"
import { getItemType } from "src/utils"
import { itemTypes } from "src/constants"
import { devices } from "src/theme/device"
import { Flex } from "src/styled"
import { Pagination } from "src/components/common"

const PaginationWrapper = styled(Flex)`
    flex: 1;
    justify-content: center;
    margin-top: 50px;

    ${devices.tablet} {
        display: none;
    }
`

const ListGrid = styled(Element)`
    display: grid;
    ${devices.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }
    ${devices.laptop} {
        grid-template-columns: repeat(2, 1fr);
    }
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 95%;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 20px;
`

const TabletGrid = styled(ListGrid)`
    ${devices.tablet} {
        display: none;
    }
`

const List = ({
    items,
    totalItemsPerPage,
    currentPage,
    setActivePage,
    isInRestaurantMenu,
}) => {
    const { ingredients } = useSelector(state => state.menu)

    if (items.length === 0) {
        return <p>No item was found.</p>
    }

    function paginate(items) {
        const startItemIndex = totalItemsPerPage * (currentPage - 1)
        return items.slice(startItemIndex, startItemIndex + totalItemsPerPage)
    }

    function getDescription(item) {
        const itemType = getItemType(item.id)
        const itemDescription = {
            [itemTypes.set]: getSetDescription,
            [itemTypes.product]: getProductDescription,
            [itemTypes.ingredient]: () => undefined,
        }
        return itemDescription[itemType](item)
    }

    function getSetDescription(set) {
        return set.products.map(
            product => `${product.name} x ${product.numberOfPieces}`
        )
    }

    function getProductDescription(product) {
        return product.ingredients.map(ingredient => `${ingredient.name}`)
    }

    return (
        <React.Fragment>
            {/* Desktop */}
            <TabletGrid>
                {paginate(items).map((item, index) => (
                    <Card
                        isInRestaurantMenu={isInRestaurantMenu}
                        key={index}
                        id={item.id}
                        name={item.name}
                        price={item.price}
                        description={getDescription(item)}
                        desc={item.description}
                        category={item.category}
                        imageURL={item.imageURL}
                    />
                ))}
            </TabletGrid>

            <PaginationWrapper>
                <Pagination
                    activePage={currentPage}
                    totalItems={items.length}
                    itemsPerPage={totalItemsPerPage}
                    setActivePage={setActivePage}
                />
            </PaginationWrapper>
        </React.Fragment>
    )
}
export default List
