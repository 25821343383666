import { Box } from "src/styled"
import { Link } from "react-scroll"
import styled from "styled-components"
import { devices } from "src/theme/device"
import { isSafari, isDesktop } from "react-device-detect"

export const SearchWrapper = styled(Box)`
    margin: 20px auto;
`

export const Container = styled.nav`
    width: 100vw;
    height: 40px;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    white-space: nowrap;
    overflow: auto hidden;
    ul {
        height: 100%;
        display: flex;
        align-items: center;
        list-style: none;
    }
`

export const Wrapper = styled(Box)`
    height: calc(100vh - 110px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden auto;
    padding-bottom: 110px;
`

export const BigContainer = styled(Box)`
  display: none;
  height: calc(100vh - 70px);
  overflow: hidden;
  top: 70px;
  /* position: ${!isDesktop && isSafari ? "sticky" : "absolute"}; */
  position:sticky;
  left: 0;
  bottom: 0;
  right: 0;
  ${devices.tablet} {
    display: block;
  }
`

export const Item = styled(Link)`
    padding: 5px 30px;
    position: relative;
    font-size: 18px;
    cursor: pointer;
    background: transparent;
    color: ${({ theme }) => theme.colors.primaryDark};
    border: none;
    border-bottom: 2px solid
        ${({ active, theme }) =>
            active ? theme.colors.primary : "transparent"};
    :focus {
        outline: none;
    }
    text-transform: capitalize;
    ${devices.tablet} {
        font-size: 16px;
        padding: 5px 15px;
    }
    :hover,
    :focus {
        color: ${({ theme }) => theme.colors.secondary};
        > div {
            display: block;
        }
    }
`

export const HeaderBox = styled(Box)`
    text-align: center;
    padding: 20px 5px 50px 5px;
    background: ${({ theme }) => theme.colors.lightGray};
`
