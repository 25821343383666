import React from "react"
import styled from "styled-components"
import { Box, Flex } from "src/styled"
import { useSelector, useDispatch } from "react-redux"

import List from "../List"
import Search from "src/components/Search"
import { Description, Title } from "../../Main/components"
import { Navigation, Loading } from "src/components/common"
import {
    setMenuSearch,
    setMenuCategory,
    setMenuPage,
} from "src/redux/menurestaurant/actions"
import { devices } from "src/theme/device"

const HeaderBox = styled(Box)`
    text-align: center;
    padding: 20px 5px 50px 5px;
    background: ${({ theme }) => theme.colors.lightGray};
`

const MenuContainer = styled(Box)`
    padding: 25px 0;
    width: 100%;
    position: relative;
`

const TOTAL_ITEMS_PER_PAGE = 12

const Container = styled(Box)`
    ${devices.tablet} {
        display: none;
    }
    height: fit-content;
`

const NavContainer = styled(Box)`
    background: white;
    ${devices.tablet} {
        position: sticky;
        top: 0;
    }
`

const ListContainer = styled(Box)`
    ${devices.tablet} {
        height: calc(100vh - 70px);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    height: fit-content;
`
const SearchWrapper = styled(Box)`
    margin: -75px auto 50px auto;
`

export default function RestaurantDesktopList() {
    const {
        isLoading,
        menuSearch,
        allCategories,
        selectedCategory,
        filteredAndSearchedMenu,
        currentPage,
    } = useSelector(state => state.menurestaurant)
    const dispatch = useDispatch()

    let filteredCategories = allCategories
    // .filter(
    //   element => element !== 'Alcohol'
    // )
    let filteredAndSearchedMenuNoAlco = filteredAndSearchedMenu
    // .filter(
    //   element => element.category !== 'Alcohol'
    // )
    console.log(filteredAndSearchedMenuNoAlco)

    let filteredSortedAndSearchedMenuNoAlco = filteredAndSearchedMenuNoAlco.reduce(
        (acc, element) => {
            if (element.name == "WARSAWianka") {
                return [element, ...acc]
            }
            return [...acc, element]
        },
        []
    )

    function setSearchFilter(search) {
        setCurrentPage(1)
        dispatch(setMenuSearch(search))
    }

    const setCurrentPage = page => dispatch(setMenuPage(page))

    function setCategory(category) {
        setCurrentPage(1)
        dispatch(setMenuCategory(category))
    }

    if (isLoading) {
        return (
            <Flex
                alignItems="center"
                justifyContent="center"
                flex={1}
                height="100%"
            >
                <Loading />
            </Flex>
        )
    }
    console.log("xd")

    return (
        <Container>
            <HeaderBox>
                <Title> Menu </Title>
                <Description>
                    Dokonaj zamówienia online z dostawą lub odbiorem osobistym w
                    naszym lokalu.
                </Description>
            </HeaderBox>

            <MenuContainer>
                <SearchWrapper>
                    <Search value={menuSearch} onChange={setSearchFilter} />
                </SearchWrapper>
                <ListContainer>
                    <Box>
                        <NavContainer>
                            {filteredCategories &&
                                filteredCategories.length > 0 && (
                                    <Navigation
                                        tabs={filteredCategories}
                                        activeTab={selectedCategory}
                                        setActiveTab={setCategory}
                                    />
                                )}
                        </NavContainer>

                        <List
                            isInRestaurantMenu={true}
                            allCategories={filteredCategories}
                            items={filteredSortedAndSearchedMenuNoAlco}
                            currentPage={currentPage}
                            setActivePage={setCurrentPage}
                            totalItemsPerPage={TOTAL_ITEMS_PER_PAGE}
                        />
                    </Box>
                </ListContainer>
            </MenuContainer>
        </Container>
    )
}
